<template>
  <div v-if="$isLoading">
    <div class="min-vh-100 position-relative">
      <CRow class="no-gutters px-3 px-sm-0">
        <b-col lg="4" class="text-center text-sm-left mb-3 mb-sm-0">
          <h1 class="mr-sm-4 header-main text-uppercase">
            รายการเงื่อนไขขนส่ง
          </h1>
        </b-col>
        <b-col lg="8" class="text-right">
          <div class="d-block d-sm-flex">
            <div class="d-flex align-items-center justify-content-end w-100">
              <router-link :to="'/shipping/details/0'">
                <b-button class="ml-2 btn-main">
                  <span class="d-none d-sm-inline one-line">เพิ่มเงื่อนไข</span>
                </b-button>
              </router-link>
              <b-dropdown class="ml-1 p-1 lang-dd border-0" right>
                <template v-slot:button-content>ตัวเลือกเพิ่มเติม</template>
                <b-dropdown-item-button
                  >Product Promotion</b-dropdown-item-button
                >
              </b-dropdown>
            </div>
          </div>
        </b-col>
      </CRow>
      <div class="mt-3 bg-white px-0 pb-3">
        <b-row class="no-gutters">
          <b-col>
            <b-table
              responsive
              class="text-center table-list"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="ไม่พบข้อมูล"
            >
              <template v-slot:cell(name)="data">
                <p class="mb-0 nobreak two-lines">{{ data.item.name }}</p>
              </template>
              <template v-slot:cell(id)="data">
                <div class="d-flex justify-content-center">
                  <router-link
                    :to="'/category/tags/details/' + data.item.tagGroupId"
                  >
                    <b-button variant="link" class="text-dark px-1 py-0">
                      แก้ไข
                    </b-button>
                  </router-link>
                  <b-button
                    variant="link"
                    class="text-dark px-1 py-0"
                    @click="openModalDelete(data.item)"
                  >
                    ลบ
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="no-gutters px-3 px-sm-0">
          <b-col
            class="form-inline justify-content-center justify-content-sm-between"
          >
            <div class="d-sm-flex m-3">
              <b-pagination
                v-model="filter.PageNo"
                :total-rows="rows"
                :per-page="filter.PerPage"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>
            </div>

            <b-form-select
              class="mr-sm-3"
              v-model="filter.PerPage"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Modal -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import axios from "axios";

export default {
  name: "shipping",
  components: {
    InputSelect,
    ModalAlertConfirm,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      modalMessage: "",
      error: "",
      fields: [
        { key: "sku", label: "ชื่อขนส่ง", class: "w-100px text-nowrap" },
        {
          key: "name",
          label: "ประเภทเงื่อนไข",
          class: "w-100px text-nowrap",
        },
        {
          key: "productTypeId",
          label: "ช่วงราคา(บาท)",
          class: "w-100px text-nowrap",
        },
        { key: "id", label: "", class: "w-100px text-nowrap" },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        PageNo: 1,
        PerPage: 10,
        Search: "",
        Status: [],
        CategoryId: [],
        SortByOrderOrId: 2,
      },
      pageOptions: [
        { value: 10, text: "10 / หน้า" },
        { value: 30, text: "30 / หน้า" },
        { value: 50, text: "50 / หน้า" },
        { value: 100, text: "100 / หน้า" },
      ],
    };
  },
  created: async function() {
    this.$isLoading = false;
    await this.getList();
    this.$isLoading = true;
  },
  methods: {
    getList: async function() {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/product/List`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.dataList;
        this.rows = resData.detail.count;
        this.isBusy = false;
      }
    },
    pagination(Page) {
      window.scrollTo(0, 0);
      this.filter.PageNo = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.PageNo = 1;
      this.filter.PerPage = value;
      this.getList();
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-menu-right {
  padding: 0rem !important;
}

::v-deep .dropdown-item {
  padding: 0.75rem 1.25rem !important;
}

::v-deep .lang-dd button:focus {
  background-color: #fff !important;
  color: #4f5d73;
}

::v-deep .lang-dd ul {
  width: 200px;
}

.menuactive {
  color: #ffb300 !important;
}

.category-popover {
  position: absolute;
  z-index: 1;
  border: 1px solid #dcdcdc;
  overflow-y: auto;
  right: 0;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.12),
    0 -1px 2px 0 rgba(0, 0, 0, 0.08);
}

.category-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: auto;
  padding: 10px;
  max-width: 100%;
  text-align: left;
}

.category-main-box {
  width: 600px;
}

.toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 600px) {
  .category-main-box {
    width: 100%;
    margin-top: 10px;
  }

  .category-popover {
    right: 15px;
  }
}
</style>
